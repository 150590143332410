<template>
<header class="ColorSkin">
	<div class="Container">
		<div class="Box">
			<div class="Logo"><a href="/"><img src="images/Logo.png" srcset="images/LogoRetina.png 2x" alt="Studio Sport"></a></div>
		</div>
	</div>
</header>
	<section class="SigninSection">
		<div class="Container">
			<div class="Box">
				<h1>Grazie!</h1>
				<div class="TxtEditor">
					<p>Abbiamo ricevuto la tua registrazione.</p>
					<p>A breve riceverai una mail con la password per accedere alla tua area riservata.</p>
				</div>
				<br/>
				<div class="Button">
					<a href="#/">Torna alla home page</a>
				</div>
			</div>

		</div>
	</section>
</template>

<script>
export default {
	name: "Thankyou",
}
</script>
